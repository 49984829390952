import Swal from 'sweetalert2';

export const infoSwal = (params)=>{
    const {
        icon = 'info',
        title = '',
        text = '',
        showConfirmButton = true,
        confirmButtonColor = '#3085d6',
        confirmButtonText = 'Aceptar',
        timer = 3000
    } = params;

    Swal.fire({
        icon,
        title,
        text,
        timer,
        showConfirmButton,
        confirmButtonColor,
        confirmButtonText,
        allowOutsideClick: false
    });
}

export const waitSwal = ()=>{
    Swal.fire({
        title: 'Espere...',
        html: '',
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        }
    });
}

export const inputMailSwal =async (callback)=>{
    const { value: email } = await Swal.fire({
        title: 'Recuperar contraseña',
        input: 'email',
        inputLabel: 'Escribe tu nombre de usuario',
        inputPlaceholder: '...',
    });
    callback(email);
}

export const mixinSwal = (params)=>{

    const {
        icon,
        title=''
    } = params

    Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true
    }).fire({
        icon,
        title
    });
}

export const closeSwal = ()=>{
    Swal.close();
}

export const deleteConfirmate = (key,text,callback)=>{
    Swal.fire({
        icon:"question",
        title:"",
        text,
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        confirmButtonColor: '#3085d6',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
            parseInt(login) === parseInt(key) ? callback(true) : callback(false);
        }
      });
}

export const questionConfirmate = (text,callback)=>{
    Swal.fire({
        title: 'Confirma',
        text,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí'
      }).then((result) => {
          callback(result.isConfirmed);
      })
}


