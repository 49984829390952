import {useEffect,useState,useRef,useCallback} from 'react';
import { fetchCustome2 } from "../../../helpers/fetch";
import { infoSwal,mixinSwal,questionConfirmate,waitSwal } from '../../../helpers/swal';

export const CategoriasHook = ()=>{

    const [data,setData] = useState({
        load:true,
        data:[]
    });

    const isMounted = useRef(true);

    const getCategorys = useCallback(async()=>{

        setData({
            load:true,
            data:[]
        });

        const categorias = await fetchCustome2( { endpoint : `/categorias`,token:false} );

        if(!isMounted.current)
            return;

        setData({
            load:false,
            data:categorias
        });

    },[]);

    const onSaveOrderCategory = async()=>{
        questionConfirmate('Deseas guardar el ordenamiento',async(resp)=>{
            if(resp){
                waitSwal();
                try{
                    const resp = await fetchCustome2({ 
                        endpoint : '/categorias/ordenar',
                        method :'PATCH',
                        body:{
                            categorias: data.data
                        },
                        json:true
                    });
                    if(resp === "success")
                        return mixinSwal({ icon: 'success',title: `Categorías ordenadas`});
                    
                    infoSwal({icon: 'error',title: '',text: `Ocurrio un error, intentelo nuevamente` ,timer:30000});
                }
                catch(e){
                    infoSwal({icon: 'error',title: 'Ocurrio un error',text: `Error de comunucación con el servidor, intentelo más tarde`,timer:30000});
                }
            }
        });
    }

    const upOrDownPositions = (position,restOrAdd)=>{
        let positionTop = -1;
        let positionCurrent = -1;

        //Retornamos un nuevo arreglo y guardamos la posición del elemento a subir y del elemento a bajar
        const newCategorys = data.data.map(cat=>{
            if(cat.posicion ===  position - restOrAdd)
                positionTop = data.data.indexOf(cat);
            if(cat.posicion === position)
                positionCurrent = data.data.indexOf(cat);
            return cat;
        });

        //Cambiamos las posciones gracias a la posición guardada previamente
        newCategorys[positionTop].posicion = position;
        newCategorys[positionCurrent].posicion = position - restOrAdd;


        //Ordenamos por orden de posición
        newCategorys.sort((a,b)=>{
            if(a.posicion > b.posicion)
                return 1;
            if(a.posicion < b.posicion)
                return -1;
            return 0;
        });

        //Actualizamos nuestro state
        setData({
            ...data,
            data : newCategorys
        })
    }


    useEffect(()=>{
       
        getCategorys();

        return () => {
            isMounted.current = false;
        }
        
    },[getCategorys]);

    
    return{
        data,
        onSaveOrderCategory,
        upOrDownPositions,
        getCategorys
    }
    
}