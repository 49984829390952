
import React , {useEffect, useRef,useContext} from 'react';
import { useSelector } from 'react-redux';
import { Link,useLocation,useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import {ContextMenu} from '../../context/MenuContext';
import { menus,stateInit } from '../../routes/tipos/menus';

export const SideBar = ({ setShowHideSideBar, widthScreen, showHideSideBar }) => {

    const {name,image,access} = useSelector( store => store.auth );
    const navigate = useNavigate();
    const refSideBar = useRef(null);
    const refDropDownMenu = useRef(null);

    const {pathname:location} = useLocation();

    const {active,setActive} = useContext(ContextMenu);


    // const [active,setActive] = React.useState({
    //     ...stateInit,
    //     [location] : 'active'
    // });

    // const [ toggleShow , setToggleShow ] = React.useState( false );

    useEffect(()=>{

        const handleClickOutside = (event)=> {
            if (refSideBar.current && !refSideBar.current.contains(event.target) && widthScreen <= 1364 && showHideSideBar === false) 
                setShowHideSideBar(true);

            // if (refDropDownMenu.current && !refDropDownMenu.current.contains(event.target)) 
            //     setToggleShow( false );
        }
    
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    },[widthScreen,showHideSideBar,setShowHideSideBar]);

    useEffect(() => {
        setActive({
            [location] :'menu-active'
        });
    },[])

   
    const changeActive = (val)=>{
        setActive({
            ...stateInit,
            [val]:'menu-active'
        });

        closeSideBarForClickItem();//si es una resolución baja al dar clic en algún módulo cerramos el sideBar
    }

    const closeSideBarForClickItem = ()=>{
        if(widthScreen <= 1364)
            setShowHideSideBar(true);
    }

    const logout = (e)=>{
        e.preventDefault();
        Swal.fire({
            title: '¿ Deseas cerrar la sesión ?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cerrar',
            cancelButtonText: 'No'
          }).then((result) => {
            if(result.isConfirmed)
                navigate('/logout')
          })
    }

    return (
        <div ref={refSideBar} className="sidebar-menu">
            <div className="sidebar-header">
                <div className="logo">
                    <img src={process.env.PUBLIC_URL + '/assets/img/logo/logo39982020.png'} alt="logo" style={{maxWidth:120,marginTop:-18}} />
                    {/* <div style={{textAlign:'center',marginTop:10}}>
                        <span style={{color:'#006231',fontWeight:800}}>ASOCIACIÓN</span>
                        <span style={{color:'#F20000',paddingLeft:10,fontWeight:800}}>PÁDEL</span>
                        <span style={{color:'#F20000',paddingLeft:10,fontWeight:800}}>JALISCO</span>
                    </div> */}
                </div>
            </div>
        
            <div className="d-flex justify-content-center" style={{paddingLeft:20,paddingRight:20,paddingTop:8,paddingBottom:8,background:'#E0DADA7C'}}>
               <span style={{fontSize:18,color:'#FFF'}}>{name}</span>
            </div>
        
            <div className="main-menu">
                <div className="menu-inner">
                    <nav>
                        <ul className="metismenu" id="menu">

                        {

                            menus(access).map(option =>(
                                <li className={active[`${option.to}`]} key={option.name}>
                                    <Link
                                        className="link-principal"
                                        to={`${option.to}`}
                                        onClick={()=>changeActive(`${option.to}`)}
                                    >
                                        <i className={`${option.icon} me-3`}></i> 
                                        {
                                            option.name  === "Iniciar sesión" ||  option.name  === "Afiliarme" ?
                                            <b><u>{option.name}</u></b> : 
                                            option.name 
                                        }
                                    </Link>
                                </li>
                            ))

                        }

                        {
                             !!name &&

                             <li>
                                 <Link
                                     className="link-principal"
                                     to=""
                                     onClick={logout}
                                 >
                                     <i className="fa fa-sign-out fa-2x fa-fw"></i>Salir
                                 </Link>
                             </li>
 
                        }

                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}


