
import {ModalXl} from '../../utils/modal/Modal';

export const TerminosJugador = ({modal,setModal})=>{

    return(
        <ModalXl modal={modal} setModal={setModal}>
            <div className="modal-crow-header text-center">
                <h4>TÉRMINOS Y CONDICIONES DE AFILIACIÓN</h4>
            </div>
            <div className="row">
                <p>Mediante el presente escrito de “TÉRMINOS Y CONDICIONES DE AFILIACIÓN” se establece que:</p>
                <p className="mt-3">
                    <b>PRIMERO</b>. – La APJ, en lo subsecuente y para los efectos del presente documento será denominada como APJ y a los jugadores, 
                    se les denominará de manera general como Afiliados. </p>
                <p className="mt-3">
                    <b>SEGUNDO</b>. - El objeto que tiene APJ es promover, difundir, practicar y contribuir al
                    desarrollo del deporte, sin que sus fines sean preponderantemente económicos. 
                </p>
                <p className="mt-3">
                    <b>TERCERO</b>. - APJ en coordinación de instituciones como CODE y/o CONADE podrá
                </p>
                <p className="mt-3 ps-5">
                    <ol>
                        <li>
                            Calificar y en su caso organizar actividades y competencias oficiales y no oficiales.
                        </li>
                        <li className="mt-3">
                            Coordinación con sus afiliados para promoción de la disciplina a la que se atiende
                        </li>
                        <li className="mt-3">
                            Trabajo en coordinación con la administración pública con el objetivo de
                            prevenir, controlar y hacer frente de detención al uso de sustancias ilegales, así
                            como cualquier otro método no permitido por los reglamentos del deporte y que
                            vayan en contra de las buenas costumbres, mismo sentido colaborar con la
                            prevención de otros problemas de salud, tales como obesidad, ansiedad entre
                            otros, mismos que pueden ser combatidos con la práctica del deporte.
                        </li>
                        <li className="mt-3">
                            Representatividad y promoción de la cultura del deporte en el territorio nacional como asociación estatal. 
                        </li>
                    </ol>
                </p>
                <p className="mt-3">
                    <b>CUARTO</b>. - Se establece mediante este documento que la afiliación a la APJ en carácter
                    de jugador, es de manera libre y espontánea y que el afiliado reconoce que, al momento de
                    concretar la afiliación y, al ser este de naturaleza civil no se podrá considerar como una
                    relación laboral, por lo que el afiliado se compromete a relevar a APJ de cualquier
                    responsabilidad en materia laboral o cualquier otra materia que en derecho corresponda.
                </p>
                <p className="mt-3">
                    <b>QUINTO</b>. - Queda asentado mediante el presente documento que la información
                    confidencial que es puesta a disposición del afiliado para con la APJ será destinada
                    únicamente para el cumplimiento del presente documento y de validez a su afiliación, por
                    lo que cualquier uso diverso de la información será motivo de la rescisión de afiliación.
                </p>
                <p className="mt-3">
                    <b>SEXTO</b>. - Se entiende que, el objeto de la APJ será el que se describe en el punto primero,
                    segundo y tercero de este documento, por lo que el afiliado revela a APJ de cualquier tipo
                    de responsabilidad, daños o perjuicios no inherentes a la descripción de los puntos
                    anteriormente mencionados. 
                </p>
                <p className="mt-3">
                    <b>SÉPTIMO</b>. - Se emite consentimiento del contenido de este escrito estableciendo que no
                    existe dolo en las disposiciones que aquí se establecen.
                </p>
            </div>

            <div className="mt-3 mb-3 text-center">
                <a href="https://apj.mx/privacidad/FORMATO-AFILIACION.pdf" download="FORMATO-AFILIACION.pdf" className="btn btn-primary btn-sm ms-3"><i className="fa fa-download" aria-hidden="true"></i> Descargar formato</a>
            </div>
           
        </ModalXl>
    )
}