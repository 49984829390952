
import {ModalXl} from '../../utils/modal/Modal';

export const AvisoPrivacidad = ({modal,setModal})=>{

    return(
        <ModalXl modal={modal} setModal={setModal}>
            <div className="modal-crow-header text-center">
                <h4>AVISO DE PRIVACIDAD INTEGRAL</h4>
            </div>
            <div className="row">
                <p>
                    <b>ASOCIACIÓN DE PADEL</b>, en adelante <b>“APJ”</b> con el objeto de respetar los derechos e
                    intereses de sus clientes y/o prospectos de clientes que compartan su información con
                    esta empresa, en relación con su intimidad y sus datos personales, así como en
                    cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en
                    Posesión de los Particulares (en adelante la “Ley”) y su reglamento (en adelante el
                    “Reglamento”), extiende el presente Aviso de Privacidad Integral.
                </p>
                <p className="mt-3 ps-5">
                    <ol>
                        <li>
                            <u><b>Responsable de la protección de sus datos personales</b></u>. La empresa
                            denominada “ASOCIACIÓN DE PADEL A.C.” en adelante “APJ”, con domicilio
                            fiscal en Av. Manuel Acuña 2929, lcal H5, col. Terranova, CP 44689, Guadalajara,
                            Jalisco., a través del área Corporativa de la empresa quien será el responsable de
                            dar el tratamiento adecuado a sus datos personales.
                        </li>
                        <li className="mt-3">
                            <u><b>Tratamiento de sus datos personales y su finalidad</b></u>. Se recaba, utiliza,
                            almacena o transfiere sus datos personales, en la medida en que las leyes
                            aplicables lo permiten, con las finalidades descritas a continuación: Finalidades
                            principales. (i) dar cumplimiento a obligaciones contraídas con nuestros clientes;
                            (ii) para integrar su expediente como cliente; (iii) proveer los servicios y productos
                            requeridos por usted; (iv) gestión financiera, de facturación y cobro (en su caso).
                            Finalidades secundarias o accesorias. (i) darle a conocer la información de los
                            servicios de “APJ”, entre la que se destaca la relacionada con promociones,
                            información de nuevos servicios y cualquier otro promocional o cuestión
                            relacionada con nuestros servicios; y (ii) evaluar la calidad del servicio, cabe
                            señalar que el tratamiento de datos personales por parte de “APJ”, se limitará al
                            cumplimiento de las finalidades aquí previstas y en caso que se pretenda tratar
                            sus datos personales para un fin distinto a los establecidos en el presente Aviso
                            de Privacidad, “APJ”, requerirá su consentimiento para tal fin, de nueva cuenta.
                        </li>
                        <li className="mt-3">
                            <u><b>Datos personales</b></u>. Para las finalidades señaladas en el presente aviso de
                            privacidad, podemos recabar sus datos personales de distintas formas; cuando
                            nos los proporciona directamente, cuando visita nuestro sitio de internet o utiliza
                            nuestros servicios en línea, y cuando obtengamos información a través de otras
                            fuentes que están permitidas por la ley. Datos personales que recabamos de forma
                            directa: Nombre (s) y apellidos y/o denominación o razón social, domicilio, teléfono
                            celular. Datos personales que recabamos por este medio pueden ser: Nombre (s)
                            y apellidos, domicilio, teléfono fijo (de casa u oficina), teléfono celular, correo
                            electrónico. Los datos personales serán recabados mediante el llenado de los
                            formatos físicos o remotos, y/o mediante la recopilación de información o
                            documentación requerida por “APJ”, ya sea de manera personal o vía electrónica
                            en cualquiera de las páginas de internet y/o correos de su propiedad, podrá
                            también recopilar datos sensibles: biométricos consistentes en imagen facial,
                            huellas dactilares y firma autógrafa digital; información médica necesaria para la
                            realización de actividades laborales y/o prestación de servicios; información de
                            salud/médica, psicológicas y toxicológicas, grabación de voz y video, resultados
                            de exámenes de conocimiento, pruebas de valores y honestidad. “APJ” no recaba
                            ni trata datos personales sensibles de sus clientes.
                        </li>
                        <li className="mt-3">
                            <u><b>Seguridad de los datos Personales</b></u>. “APJ” implementa medidas de seguridad,
                            técnicas, administrativas y físicas, necesarias para proteger sus datos personales
                            y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no
                            autorizado, por lo que realizará las siguientes acciones: (a) Confidencialidad de
                            la información. “APJ”, guardará confidencialidad respecto de sus datos
                            personales recabados, mismas que subsistirán aun después de finalizar las
                            relaciones comerciales o de otra naturaleza. (b) Notificación de
                            confidencialidad. En caso que, por algún motivo “APJ”, se vea en la necesidad
                            de proporcionar sus datos personales a terceros (en los términos previstos en la
                            Ley o en el presente Aviso de Privacidad), notificará a dichos terceros la obligación
                            de cumplir con las disposiciones de la Ley y la confidencialidad de sus datos
                            personales. (c) Administración de bases de datos. Los datos personales son
                            administrados y resguardados mediante el uso de bases de datos, por las
                            personas designadas para tal efecto, sin que se permita su uso, consulta, manejo
                            o acceso a personas no autorizadas. (d) Aviso sobre cookies y web beacons.
                            “APJ”, utiliza varias tecnologías para mejorar la eficiencia de sus sitios web,
                            incluyendo su experiencia cuando navega por dichos sitios. Entre estas
                            tecnologías se incluye el uso de cookies. Las cookies son pequeñas cantidades
                            de información que se almacenan en el navegador utilizado por cada usuario para
                            que el servidor recuerde cierta información que posteriormente pueda utilizar. Esta
                            información permite identificar y guardar sus preferencias personales para
                            brindarle una mejor experiencia de navegación. Por su parte, la web beacons son
                            imágenes insertadas en una página de Internet o correo electrónico, que puede
                            ser utilizado para monitorear el comportamiento de un visitante, como almacenar
                            información sobre la dirección IP del usuario, duración del tiempo de interacción
                            en dicha página y el tipo de navegador utilizado, entre otros.
                        </li>
                        <li className="mt-3">
                            <u><b>Consentimiento</b></u>. En caso que usted no manifieste oposición mediante solicitud
                            por escrito al contenido y alcances del presente Aviso de Privacidad, dentro de un
                            periodo de dos meses, contados a partir de la fecha en la que “APJ”., le informe
                            sobre este o sobre sus modificaciones, se entenderá que usted otorga su
                            consentimiento tácito para que “APJ”, efectúe el tratamiento de sus datos
                            personales con base en las finalidades del tratamiento. Independientemente de lo
                            anterior en caso que desee revocar dicho consentimiento o limitar el uso o
                            divulgación de sus datos personales, en relación con una o varias de las
                            finalidades de su tratamiento en cualquier momento, podrá manifestarlo mediante
                            solicitud por escrito, enviada al correo electrónico o dirección mencionados en el
                            punto IX del presente Aviso de Privacidad.
                        </li>
                        <li className="mt-3">
                            <u><b>Cambios al Aviso de Privacidad</b></u>.“APJ”, se reserva el derecho de efectuar, en
                            cualquier momento, modificaciones o actualizaciones al presente Aviso de
                            Privacidad, para la atención de novedades legislativas o jurisprudenciales,
                            políticas internas, nuevos requerimientos para la prestación u ofrecimiento de sus
                            productos y servicios, o por prácticas comerciales. Por lo anterior, en caso que
                            “APJ”, llegare a modificar el contenido del presente Aviso de Privacidad, lo hará
                            de su conocimiento, a través de correo electrónico.
                        </li>
                        <li className="mt-3">
                            <u><b>Transferencia de datos personales</b></u>. Sus datos personales podrían ser
                            transferidos a sociedades subsidiarias, filiales, afiliadas y controladoras de “APJ”,
                            e incluso a terceras personas, nacionales o extranjeras, salvo que usted (titular)
                            manifieste expresamente su oposición, en términos de lo establecido por la Ley y
                            su Reglamento. En estos casos,“APJ”, llevará la transferencia de los datos
                            personales previa celebración de convenios de confidencialidad siempre y cuando
                            (i) el proveedor o persona a quien se le transmitan acepte someter el tratamiento
                            de los datos personales al presente Aviso de Privacidad, no tratándose de alguno
                            de los supuestos establecidos en el artículo 37 de la Ley. Si usted no manifiesta
                            su oposición para que sus datos personales sean transferidos a terceros, se
                            entenderá tácitamente que ha otorgado a “APJ”, su consentimiento para ello. 
                        </li>
                        <li className="mt-3">
                            <u><b>Informativo</b></u>.- Si usted considera que su derecho a la protección de sus datos ha
                            sido lesionado por alguna conducta u omisión de nuestra parte, o considera alguna
                            violación al contenido de las disposiciones previstas en la Ley Federal de
                            Protección de Datos Personales en Posesión de Particulares, su reglamento y
                            demás ordenamientos aplicables, podrá interponer su queja ante el Instituto
                            Nacional de Transparencia, Acceso a la Información y Protección de Datos
                            Personales (INAI)
                        </li>
                        <li className="mt-3">
                            <u><b>Revocación de consentimiento y Derechos de Acceso, Rectificación,
                            Cancelación u Oposición</b></u>. En su carácter de titular de datos personales, puede
                            presentar o enviar al área corporativa de “APJ”, una solicitud para la revocación
                            de su consentimiento, el acceso, rectificación, limitación, cancelación u oposición,
                            respecto de los mismos. El departamento de datos personales dará trámite a sus
                            solicitudes y fomentará la protección de los datos personales al interior de “APJ”,
                            Para cualquier solicitud de este tipo, que le competa al área corporativa de “APJ”,
                            por favor envíe un correo electrónico a preciado_diego@hotmail.com, con gusto
                            será atendida.
                        </li>
                    </ol>
                </p>
                <p className="mt-3">
                    <b>El Área Corporativa de “APJ”</b>, comunicará al titular de los datos personales la
                    determinación adoptada, en un plazo no mayor a 20 días hábiles contados desde
                    la fecha en que se recibió la solicitud. 
                </p>
                <p className="mt-3">
                    El Área le proporcionará la atención necesaria para el ejercicio de sus derechos
                    ARCO y/o revocación del consentimiento y/o limitación-divulgación de sus datos
                    personales. Además, velará por la protección de sus datos personales al interior
                    de la organización.
                </p>
                <p className="mt-3">Nombre: Área Corporativa de “APJ”.</p>
                <p>Dirección: Avenida de las Américas, número 1930, colonia Country Club, Guadalajara, Jalisco.</p>
                <p>Horario de atención: 8:30 a 17:30 de lunes a viernes.</p>
                <p>Teléfono: 3310910922</p>
               

            </div>
           
        </ModalXl>
    )
}