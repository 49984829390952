import {createContext,useState} from 'react';

export const ContextAdministracion = createContext();

export const AdministracionProvider = ({children})=>{
    
    //indicamos la pantalla a amostrar 
    //----> 0 pantalla para mostrar la lista
    //----> 1 pantalla para mostrar el detalle de algún elemento en particular
    const [screen,setScreen] = useState(0);

    //Indica el id del elemento a consultar
    const [id,setId] = useState(0);
    
    return (
        <ContextAdministracion.Provider value={{
            screen,setScreen,
            id,setId
        }}>
            {children}
        </ContextAdministracion.Provider>
    )
}

