//recibe una cadena y añade caracteres, ejemplo: recibe 1122334455 return 11-22-33-44-55
export const addChar = (cadena, caracter, pasos) => {
    if(!cadena)return "";
    const longitudCadena = cadena.length;
    let cadenaConCaracteres = "";
    for (let i = 0; i < longitudCadena; i += pasos) {
        if (i + pasos < longitudCadena) 
            cadenaConCaracteres += cadena.substring(i, i + pasos) + caracter;
        else 
            cadenaConCaracteres += cadena.substring(i, longitudCadena);
    }
    return cadenaConCaracteres;
}

export const maskDireccion = (value)=>{
    let temp = value;

    if( !temp.match(/^[0-9a-zA-ZÀ-ÿñÑ\s-.,]+$/) )
        return temp.substring(0, temp.length - 1);
    if( temp.match(/--/g) )
        return temp.substring(0, temp.length - 1);

    return temp;
}

export const maskFree = (value)=>{

    if(value.trim() === "")
        return "";

    let temp = value;

    if( temp.match(/[<>"\'&]+/) )
        return temp.substring(0, temp.length - 1);
    if( temp.match(/--/g) )
        return temp.substring(0, temp.length - 1);

    return temp;
}

export const maskEmail = (value)=>{
    let temp = value;

    if( temp.match(/[()<>,;:"\[\]ç%&*]+/) )
        return temp.substring(0, temp.length - 1);
   
    return temp;
}

export const maskMoney1000 = (value)=>{
    let temp = value;
    console.log(temp)

    // if(temp.length === 1 && temp=== ".")
    //     temp = "0.";
    // if(temp.length === 2 && temp=== "00")
    //     temp = "0.0";

    //if( !temp.match(/^(\d{1,3},?(\d{3},?)*\d{3}(.\d{0,3})?|\d{1,3}(.\d{2})?)$/) )
    if( !temp.match(/^(\d{1,3},?(\d{3},?)*\d{3}(.\d{0,3})?|\d{1,3}(.\d{2})?)$/) )
         return temp.substring(0, temp.length - 1);

    return temp;
}

//1200 ----> 1,200
export const maskMoney = (value)=>{
    let temp = value;

    if( !temp.match(/^[0-9,.]{1,}$/) )
        return temp.substring(0, temp.length - 1);
  
    temp = temp.replace(/[,]/g,'');

    temp = temp.split('').reverse().join('');
    temp = addChar(temp,',',3);
    temp = temp.split('').reverse().join('');
    
    return temp;
}

//12000.00 ---->  1,200
export const disabledMaskMoney = (value)=>{
    let temp = value;

    temp = temp.split('.')[0];
    
    temp = temp.split('').reverse().join('');
    temp = addChar(temp,',',3);
    temp = temp.split('').reverse().join('');
    
    return temp;
}

export const maskNombresPropios = (value)=>{
    let temp = value;

    if( !temp.match(/^[a-zA-ZÀ-ÿñÑ\s]+$/) )
        return temp.substring(0, temp.length - 1);

    return temp;
}

export const maskNumeroDocumento = (value)=>{
    let temp = value;

    if( !temp.match(/^[a-zA-Z0-9\s]{0,}$/) )
        return temp.substring(0, temp.length - 1);

    temp = temp.replace(/[\s]/g,'');
   
    temp = temp.split('').reverse().join('');
    temp = addChar(temp,' ',3);
    temp = temp.split('').reverse().join('');
   
    return temp;
}

export const maskNumeroDocumento2 = (value)=>{
  
    if(!value)
        return "";

    let temp = value.toString();
    let temp2 = ""

    for(let i=0; i < temp.length; i++){
        if(i%3===0 && i > 0)
            temp2 += " ";
        temp2 += temp[i];
    }

    return temp2;
}

export const maskPostal = (value)=>{
    let temp = value;

    if( !temp.match(/^[0-9]{0,5}$/) )
        return temp.substring(0, temp.length - 1);

    return temp;
}

//return 33-33-33-33-33
export const maskTelefono = (value)=>{
   
    let temp = value;

    if( !temp.match(/^[0-9-]{0,14}$/) )
        return temp.substring(0, temp.length - 1);;

    temp = temp.replace(/[-]/g,'');
    temp = addChar(temp,'-',2);
    return temp;
}


export const maskTelefono2 = (value)=>{
  
    if(!value)
        return "";

    let temp = value.toString();
    if(temp.length !== 10)
        return "";
    temp = temp.split('');
    return `${temp[0]}${temp[1]}-${temp[2]}${temp[3]}-${temp[4]}${temp[5]}-${temp[6]}${temp[7]}-${temp[8]}${temp[9]}`;
}

export const email = (value)=>{
    if (value.trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) )
        return false;
    return true;
}

export const numeroDocumento =(value)=>{
    if(value.match(/^[a-zA-Z0-9\s]{4,}$/))
        return false;
    return true;
}

export const postal = (value)=>{
    const temp = value.toString();
    if(temp.match(/^[0-9]{5}$/))
        return false;
    return true;
}

export const telefono = (value)=>{
    if(value.match(/^[0-9-]{14}$/))
        return false;
    return true;
}

export const pass = (value)=>{
    if(value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?_.])[A-Za-z\d@$!%*?_.]{10,15}$/))
        return false;
    return true;
}



