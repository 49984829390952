import {useState,useCallback,useEffect,useRef} from 'react';
const baseUrl = process.env.REACT_APP_API_URL;

export const GetPaisesHook = ()=>{

    const [paises, setPaises] = useState({
        load:true,
        data:[]
    });

    const isMounted = useRef(true);

    const getPaises = useCallback(async()=>{
        
        let resp = await fetch(`${baseUrl}/paises`);
        resp = await resp.json();

        if(!isMounted.current)
            return;
      
        setPaises({
            load:false,
            data:resp
        });

    },[]);

    useEffect(()=>{
        getPaises();
    },[getPaises]);

    useEffect(()=>{
        return () => {
            isMounted.current = false;
        }
    },[]);

    return{
       paises
    }
}