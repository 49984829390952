

export const formatDate = (value)=>{

    if(!value || value.trim()==="")
        return "";

    if(!value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/))
        return "";
    const temp = value.split('-');
    return `${temp[2]}-${temp[1]}-${temp[0]}`;
}

export const formatDateTime = (value)=>{

    if(!value || value.trim()==="")
        return "";

    if(!value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2}:[0-9]{2}:[0-9]{2}$/))
        return "";
        
    const temp = value.split(' ');
    const date = temp[0].split('-');
    const time = temp[1];

    return {
        date:`${date[2]}-${date[1]}-${date[0]}`,
        time
    }
}

export const quitBr = (value)=>{
    if(!value || value.trim()==="")
        return "";
    return value.replace(/<br\s*[\/]?>/gi,"");
}



//calcular la edad de una persona

export const calcular_edad = (fecha)=>{

    //calculo la fecha de hoy
    const hoy=new Date();
    
    const array_fecha = fecha.split("-")
    //si el array no tiene tres partes, la fecha es incorrecta
    if (array_fecha.length!=3)
       return -1;

    //compruebo que los ano, mes, dia son correctos
    const ano = parseInt(array_fecha[0]);
    if (isNaN(ano))
       return -1;

    const mes = parseInt(array_fecha[1]);
    if (isNaN(mes))
       return -1;

    const dia = parseInt(array_fecha[2]);
    if (isNaN(dia))
       return -1;

    //resto los años de las dos fechas
    const edad=hoy.getFullYear() - ano - 1; //-1 porque no se si ha cumplido años ya este año


    //si resto los meses y me da menor que 0 entonces no ha cumplido años. Si da mayor si ha cumplido
    if (hoy.getMonth() + 1 - mes < 0) //+ 1 porque los meses empiezan en 0
       return edad;
    if (hoy.getMonth() + 1 - mes > 0)
       return edad+1;

    //entonces es que eran iguales. miro los dias
    //si resto los dias y me da menor que 0 entonces no ha cumplido años. Si da mayor o igual si ha cumplido
    if (hoy.getUTCDate() - dia >= 0)
       return edad + 1;

    return edad
}