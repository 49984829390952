import {useState,useCallback} from 'react';
const baseUrl = process.env.REACT_APP_API_URL;

export const GetColoniasHook = ()=>{

    const [colonias, setColonias] = useState({
        load:true,
        data:[]
    });

    const getColonias = useCallback(async(codigo)=>{
        let resp = await fetch(`${baseUrl}/postal/${codigo}`);
        resp = await resp.json();
      
        setColonias({
            load:false,
            data:resp
        });

    },[]);

    return{
        getColonias,
        colonias,
        setColonias
    }
}