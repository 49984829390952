import React from 'react'

export const Footer = () => {
    return (
        <footer style={{ /*position:'fixed',bottom:0,left:0,right:0*/ background:'#e0dada'}}>
            <div className="footer-area" style={{height:100,padding:5}}>
                <p>APJ</p>
                <p>© Copyright 2023. All right reserved</p>
            </div>
        </footer>
    )
}


