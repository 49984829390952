import {useState,useRef,useContext, useEffect}  from 'react';
import {useSelector} from 'react-redux';
import {ContextMenu} from '../../../context/MenuContext';
import { useNavigate, Navigate} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {GetPaisesHook} from './GetPaisesHook';
import {GetColoniasHook} from './GetColoniasHook';
import {CategoriasHook} from '../../administracion/otros/CategoriasHook';
import {Load} from '../../utils/load/Load';
import {infoSwal,waitSwal} from '../../../helpers/swal';
import { fetchCustome2 } from '../../../helpers/fetch';
import {maskDireccion,maskEmail,maskNombresPropios,maskNumeroDocumento,maskPostal,maskTelefono,email,numeroDocumento,postal,telefono} from '../../../helpers/validaciones';
import {AvisoPrivacidad} from './AvisoPrivacidad';
import {TerminosJugador} from './TerminosJugador';
import { calcular_edad } from '../../../helpers/various';

import './form.css';

export const Form = ()=>{

    const {name} = useSelector( store => store.auth );
    const {setActive} = useContext(ContextMenu);
    const navigate = useNavigate();
    const {paises} = GetPaisesHook();

    const {data:categorias} = CategoriasHook();
    const[controlCategorias,setControlCategorias] = useState([]);//Pintamos solo las categorias requeridas
    const allCategorys = useRef([]);//Guardamos todas las categorias que existan en la BD
    const isMounted = useRef(false);//Hasta que se cargue el DOM validamos lo de edad y sexo
    const edad = useRef(0);
    const sexo = useRef(1);
    const valorCategoria2 = useRef(null);



    const {getColonias,colonias,setColonias} = GetColoniasHook();
    const [modal,setModal] = useState(false);//toggle modal
    const [modal2,setModal2] = useState(false);//toggle modal

    const [mensaje,setMensaje] = useState('');
  
    const codigoAnterior = useRef(0);
    const captchaRef = useRef(null);

    const [form,setForm] = useState({
        nombre:'',
        primer_apellido : '',
        segundo_apellido : '',
        fecha_nacimiento : '',
        categoria : 1,
        sexo : 1,
        telefono : '',
        numero_documento : '',
        email : '',
        pais : 78,
        codigo_postal : '',
        colonia : '',
        direccion : '',
        tipo_documento : 1
    });


    useEffect(()=>{
        allCategorys.current = categorias.data;//Guardo todas las categorías originales
        const soloCategoriasHombres = categorias.data.filter(cat=>cat.id !== 7 && cat.id !== 17);
        setControlCategorias(soloCategoriasHombres);//Al iniciar quitamos las categorías de mujeres
    },[categorias]);


    useEffect(()=>{
        if(isMounted.current){

            if(parseInt(sexo.current) === 0)//Para las mujeres no aplica la edad
                return

            if(edad.current > 50){
                setForm(form=>({
                    ...form,
                    categoria:16
                }));
            }
            else{
                setForm(form=>({
                    ...form,
                    categoria:1
                }));
            }
        }
    },[form.fecha_nacimiento]);

    useEffect(()=>{
        if(isMounted.current){
            if(parseInt(sexo.current) && edad.current > 50){//Hombre mayor a 50
                setForm(form=>({
                    ...form,
                    categoria:16
                }));
            }
            else if(parseInt(sexo.current)){//Hombre menor a 50
                setForm(form=>({
                    ...form,
                    categoria:1
                }));
            }
            else{//Mujer de cualquier edad
                 setForm(form=>({
                    ...form,
                    categoria:17
                }));
            }
        }
        else 
            isMounted.current = true;
    },[form.sexo]);


    const onSubmit = async (e)=>{
        e.preventDefault();

        let errores = {name:'',error:false};
        
        Object.keys(form).forEach(input=>{

            if( input === 'nombre' && form[input].trim() === "" && errores.error === false)
                errores = {name:'Nombre',error:true};

            else if(input === 'primer_apellido' && form[input].trim() === "" && errores.error === false)
                errores = {name:'Primer apellido',error:true};
           
            else if(input === 'fecha_nacimiento' && form[input].trim() === "" && errores.error === false)
                errores = {name:'Fecha de nacimiento',error:true};

            else if(input === 'telefono' && telefono(form.telefono) && errores.error === false)
                    errores = {name:'Teléfono',error:true};

            else if(input === 'numero_documento' && numeroDocumento(form.numero_documento) && errores.error === false)
                errores = {name:'Número de documento',error:true};

            else if(input === 'email' && email(form.email) && errores.error === false)
                errores = {name:'Email',error:true};

            else if(input === 'codigo_postal' && postal(form.codigo_postal) && errores.error === false)
                errores = {name:'Código postal',error:true};

            else if(input === 'colonia' && form[input].trim() === "" && errores.error === false)
                errores = {name:'Colonia',error:true};

            else if(input === 'direccion' && form[input].trim() === "" && errores.error === false)
                errores = {name:'Dirección',error:true};
            
        });

        if(errores.error)
            return infoSwal({title:errores.name,text:'Debes llenar el campo'});

       // waitSwal();

        const temp = {...form};
        temp.telefono = form.telefono.replace(/[-]/g,'');
        temp.numero_documento = form.numero_documento.replace(/[\s]/g,'');
        temp.captcha =  captchaRef.current.getValue();

        //Verificamos si se selecciono una segunda categoría
        if( (valorCategoria2.current && parseInt(temp.sexo) === 0) || (valorCategoria2.current && edad.current > 50) )
            temp.categoria2 = valorCategoria2.current;
        else
            delete temp.categoria2;


        //return console.log(temp)

        try{
            const resp = await fetchCustome2({ 
                endpoint : '/afiliacion',
                method :'POST',
                body: temp, 
                json:true,
                token: false
            });
    
            if(resp.id){
                infoSwal({icon:'success',title:'Registro correcto',text:'Recibiras un correo con tu usuario y contraseña',timer:60000});
                setActive({ '/inicio' :'menu-active'});
                navigate('/inicio');
            }
            else if(resp.errors){
                if(resp.errors.email)
                    return infoSwal({icon:'error',title:'Email ya existe',text:'Captura un email distinto',timer:60000});
                else if(resp.errors.captcha)
                    return infoSwal({icon:'error',title:'Captcha',text:'Debes llenar el captcha',timer:60000});
                else
                    return infoSwal({icon:'error',title:'ocurrio un erorr',text:'Intentelo nuevamente',timer:60000});
            }
        }
        catch(e){
            return infoSwal({icon:'error',title:'ocurrio un erorr',text:'Intentelo nuevamente',timer:60000});
        }
    }

    const filtrarCategorias = (value)=>{
        sexo.current = parseInt(value);

        if(parseInt(value) && edad.current > 50)
            setControlCategorias(categorias.data.filter(cat=>cat.id === 16));//Solo ponemos cat + 50
        else if(parseInt(value))
            setControlCategorias(categorias.data.filter(cat=>cat.id !== 7 && cat.id !== 17));//quitamos las categorías de mujeres
        else
            setControlCategorias(categorias.data.filter(cat=>cat.id === 7 | cat.id === 17));//quitamos las categorías de hombres
    }

    const filtrarFechaNacimiento = (value)=>{

        if(parseInt(form.sexo) === 0)//Para las mujeres no aplica la edad
            return;

        if(value){
            edad.current = calcular_edad(value);
            if(edad.current > 50)
                setControlCategorias(categorias.data.filter(cat=>cat.id === 16));//Solo ponemos cat + 50
            else
                setControlCategorias(categorias.data.filter(cat=>cat.id !== 7 && cat.id !== 16 && cat.id !== 17));//quitamos las categorías de mujeres y + 50
        }
        
    }



    const onChange = (e)=>{

        let value = '';
        if(e.target.name === "telefono")
            value = maskTelefono(e.target.value);
        else if(e.target.name === "codigo_postal")
            value = maskPostal(e.target.value);
        else if(e.target.name === "nombre" || e.target.name === "primer_apellido" || e.target.name === "segundo_apellido")
            value = maskNombresPropios(e.target.value);
        else if(e.target.name === "direccion")
            value = maskDireccion(e.target.value);
        else if(e.target.name === "email")
            value = maskEmail(e.target.value);
        else if(e.target.name === "numero_documento")
            value = maskNumeroDocumento(e.target.value);
        else if(e.target.name === "sexo"){
            value = e.target.value;
            filtrarCategorias(e.target.value);
        }
        else if(e.target.name === "fecha_nacimiento"){
            value = e.target.value;
            filtrarFechaNacimiento(e.target.value);
        }
        else
            value = e.target.value;

        setForm({
            ...form,
            [e.target.name] : value
        });

    }

    const onBlur = (e)=>{
        if(e.target.value.length !== 5){
            
            setMensaje('');

            setColonias({
                load:true,
                data:[]
            });

            codigoAnterior.current = 0;

            return;
        }
        
        if( codigoAnterior.current === parseInt(e.target.value) )//el mismo código 
            return;
        else
            codigoAnterior.current = parseInt(e.target.value);
        
        
        setMensaje('Cargando...');

        setColonias({
            load:true,
            data:[]
        });

        getColonias(e.target.value);//Solicitamos la lista de colonias
   
    }

    const cerrarLogin = ()=>{
        setActive({ '/inicio' :'menu-active'});
        navigate('/inicio');
    }

   
    if(!!name)
        return <Navigate to="/inicio"/>;

    return(
        <>

            {

                paises.load 
                
                ?
                
                <Load />
            
                :

                <>
                    <div id="afiliacion">        
                        <div className="d-flex justify-content-center">

                            <div className="d-flex justify-content-center position-absolute" style={{top:15}}>
                                <div 
                                onClick={cerrarLogin}
                                style={{width:50,height:50,textAlign:'center',padding:12,borderRadius:'50%',fontSize:19,background:'#F20000',color:'#FFF',cursor:'pointer',opacity:.78}}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div className=" position-absolute"  style={{top:80,zIndex:11}}>
                                <img src={process.env.PUBLIC_URL + '/assets/img/logo/logo39982020.png'} alt="logo" style={{maxWidth:80}} />
                            </div>

                            <form onSubmit={onSubmit} noValidate>

                                <div className="row">
                                    <div className="form-outline mb-3" style={{marginTop:60}}>
                                        <label className="form-label">Nombre</label>
                                        <input type="input" className="form-control text-uppercase" value={form.nombre} name="nombre" onChange={onChange}/>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Primer apellido</label>
                                        <input type="input" className="form-control text-uppercase" value={form.primer_apellido} name="primer_apellido" onChange={onChange}/>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Segundo apellido</label>
                                        <input type="input" className="form-control text-uppercase" value={form.segundo_apellido} name="segundo_apellido" onChange={onChange}/>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Fecha de nacimiento</label>
                                        <input type="date" className="form-control"  value={form.fecha_nacimiento} name="fecha_nacimiento" min={`${new Date().getFullYear() - 75}-01-01`} max={`${new Date().getFullYear() - 15}-01-01`} onChange={onChange}/>
                                    </div>

                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Categoría</label>
                                        <select className="form-control text-uppercase"  value={form.categoria} name="categoria" onChange={onChange} disabled={categorias.load}>
                                            {
                                                categorias.load 
                                                ?
                                                    <option value=""></option> 
                                                :
                                                    controlCategorias.map(cat =><option value={cat.id} key={cat.id}>{cat.nombre}</option>)
                                            }
                                        </select>
                                    </div>


                                        {
                                           ( parseInt(form.categoria) === 7 || parseInt(form.categoria) === 16 || parseInt(form.categoria) === 17)

                                            &&

                                           <CategoriaSecundaria categoriaBase={parseInt(form.categoria)} categorias={allCategorys} valorCategoria2={valorCategoria2}/> 

                                        }


                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Sexo</label>
                                        <select className="form-control text-uppercase"  value={form.sexo} name="sexo" onChange={onChange}>
                                        <option value="1">Hombre</option>
                                        <option value="0">Mujer</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Teléfono</label>
                                        <input type="input" className="form-control" value={form.telefono} name="telefono" onChange={onChange}/>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Documento de identificación</label>
                                        <div className="mt-3" style={{fontSize:14}}>
                                            <input type="radio" value={1} name="tipo_documento" defaultChecked style={{cursor:'pointer'}} id="ine" onChange={onChange}/> <label htmlFor="ine" style={{cursor:'pointer'}}>INE</label>
                                            <input type="radio" value={2} name="tipo_documento"  className="ms-5" style={{cursor:'pointer'}} id="pasaporte" onChange={onChange}/> <label htmlFor="pasaporte" style={{cursor:'pointer'}}>Pasaporte</label>
                                            <input type="radio" value={3} name="tipo_documento"  className="ms-5" style={{cursor:'pointer'}} id="dni" onChange={onChange}/> <label htmlFor="dni" style={{cursor:'pointer'}}>DNI</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Número de documento</label>
                                        <input type="input" className="form-control text-uppercase" value={form.numero_documento} name="numero_documento" onChange={onChange}/>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Email</label>
                                        <input type="input" className="form-control"  value={form.email} name="email" onChange={onChange}/>
                                    </div>

                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Nacionalidad</label>
                                        <select className="form-control" value={form.pais} onChange={onChange} name="pais">
                                        {
                                            paises.data.map(pais=>(
                                                <option key={pais.id} value={pais.id}>{pais.nombre}</option>
                                            ))
                                        }
                                    
                                        </select>
                                    </div>

                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Código postal</label>
                                        <input type="input" className="form-control"  value={form.codigo_postal} name="codigo_postal" onChange={onChange} onBlur={onBlur}/>
                                    </div>

                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Colonia</label>
                                        <select className="form-control text-uppercase"  value={form.colonia} name="colonia" disabled={colonias.load} onChange={onChange}>
                                        {
                                            colonias.load ?
                                            
                                            <option value="">{mensaje}</option> :

                                            <>
                                                <option value=""> </option>
                                                {
                                                    colonias.data.colonias.map(colonia=>(
                                                        <option key={colonia.id_registro} value={colonia.id_registro}>{colonia.colonia}</option>
                                                    ))
                                                }
                                            </>
                                        }
                                    
                                        </select>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Estado</label>
                                        <input type="input" className="form-control text-uppercase" value={colonias.load ? "" : colonias.data.estado} disabled/>
                                    </div>
                                    <div className="col-md-6 form-outline mb-3">
                                        <label className="form-label">Municipio</label>
                                        <input type="input" className="form-control text-uppercase" value={colonias.load ? "" : colonias.data.municipio} disabled/>
                                    </div>
                                    <div className="form-outline mb-3">
                                        <label className="form-label">Dirección</label>
                                        <input type="input" className="form-control text-uppercase"  value={form.direccion} name="direccion" onChange={onChange}/>
                                    </div>

                                    {/* <hr style={{marginTop:5,color:'#8F8B8B'}}/> */}

                                    {/* <div className="row">
                                        <div className="col-md-11 text-right">
                                            <p style={{fontSize:11}}>Me comprometo a dar conformidad de la presente afiliación en forma escrita antes de mi primer partido oficial de la APJ.</p>
                                        </div>
                                        <div className="col-sm-1 mb-3">
                                            <div style={{cursor:'pointer'}} onClick={changeAccept}>
                                                <i className={`fa ${accept} fa-2x me-1`} style={{cursor:'pointer'}}></i>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <hr style={{marginTop:1,color:'#8F8B8B'}}/> */}

                                     <div className="row">
                                        <div className="col-md-6 text-center mb-2">
                                            <p style={{fontSize:13,color:'#8F8B8B'}}>
                                                <span style={{cursor:'pointer'}} onClick={()=>setModal(true)}><u>Aviso de privacidad</u></span>
                                                {/* <a href="https://apj.mx/privacidad/aviso_privacidad.pdf" download="aviso_de_privacidad_apj.pdf" className="btn btn-primary btn-sm ms-3"><i className="fa fa-download" aria-hidden="true"></i> Descargar</a> */}
                                            </p>
                                        </div>
                                        <div className="col-md-6 text-center mb-2">
                                            <p style={{fontSize:13,color:'#8F8B8B'}}>
                                                <span style={{cursor:'pointer'}} onClick={()=>setModal2(true)}><u>Terminos y condiciones jugador</u></span>
                                                {/* <a href="https://apj.mx/privacidad/aviso_privacidad.pdf" download="aviso_de_privacidad_apj.pdf" className="btn btn-primary btn-sm ms-3"><i className="fa fa-download" aria-hidden="true"></i> Descargar</a> */}
                                            </p>
                                        </div>
                                    </div>

                                    {/* <hr style={{marginTop:5,color:'#8F8B8B'}}/> */}

                                    {/* <p style={{fontSize:11,color:'#8F8B8B',marginBottom:10}}>Afiliación sin costo hasta el 30/06/2023, a partir del 01/07/2023 $ 400.00 MXN</p> */}

                                    <hr style={{marginTop:5,color:'#8F8B8B'}}/>

                                    <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}/>

                                    <div>
                                        <button type="submit" className="btn btn-danger btn-lg btn-block mt-2 mb-4 btn-suscribe">Afiliarme</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </>
            }

            {
                modal && <AvisoPrivacidad modal={modal} setModal={setModal} />
            }

            {
                modal2 && <TerminosJugador modal={modal2} setModal={setModal2} />
            }

        </>
    )
}



const CategoriaSecundaria = ({categoriaBase,categorias,valorCategoria2})=>{
    
   let temp = categorias.current;

    // if(categoriaBase === 16 || categoriaBase === 17){
    //     temp =  temp.filter(cat => cat.id === 2 || cat.id === 3);//Filtramos sólo las opciones que puede tener
    //     if(!valorCategoria2.current || ![2,3].includes(valorCategoria2.current))//Evitar algún error si el usuario se pone a jugar con los select, garantizamos que debe ir la catego corrspondiente
    //         valorCategoria2.current = 2;//Asignamos una de las opciones posibles
    // }
    // else{
    //     temp =  temp.filter(cat => cat.id === 4 || cat.id === 5 || cat.id === 6);//Filtramos sólo las opciones que puede tener
    //     if(!valorCategoria2.current || ![4,5,6].includes(valorCategoria2.current))
    //         valorCategoria2.current = 4;//Asignamos una de las opciones posibles
    // }

    return(
        <>
            <div className="alert alert-secondary" role="alert">
                Puedes seleccionar una categoría opcional en la cual independientemente de tu categoría principal tambien puedes jugar
            </div>
        
            <div className="col-md-12 form-outline mb-3">
                <label className="form-label">Categoría opcional</label>
                <select className="form-control text-uppercase" onChange={(e)=>{valorCategoria2.current = parseInt(e.target.value)}}  >
                    {
                        temp.map(cat =><option value={cat.id} key={cat.id}>{cat.nombre}</option>)
                    }
                </select>
            </div>
        </>
    )
    
}