import { Suspense, lazy } from 'react';
import { Load } from '../../componets';
const HomeScreen = lazy(() => import('../../componets/home/HomeScreen'));
const PerfilScreen = lazy(() => import('../../componets/perfil/PerfilScreen'));
const TorneosScreen = lazy(() => import('../../componets/torneos/TorneosScreen'));
const ReglamentosScreen = lazy(() => import('../../componets/reglamentos/ReglamentosScreen'));
const GaleriasScreen = lazy(() => import('../../componets/galerias/GaleriasScreen'));
const RankingScreen = lazy(() => import('../../componets/ranking/RankingScreen'));
const CalendarioScreen = lazy(() => import('../../componets/calendario/CalendarioScreen'));
const BeneficiosScreen = lazy(()=>import('../../componets/beneficios/BeneficiosScreen'));


export const rutasEstandar = [
    {
        path: '/inicio',
        element: (<Suspense fallback={<Load/>}> <HomeScreen /> </Suspense>)
    },
    {
        path: '/perfil',
        element: (<Suspense fallback={<Load/>}> <PerfilScreen /> </Suspense>)
    },
    {
        path: '/torneos',
        element: (<Suspense fallback={<Load/>}> <TorneosScreen /> </Suspense>)
    },
    {
        path: '/reglamentos',
        element: (<Suspense fallback={<Load/>}> <ReglamentosScreen /> </Suspense>)
    },
    {
        path: '/galerias',
        element: (<Suspense fallback={<Load/>}> <GaleriasScreen /> </Suspense>)
    },
    {
        path: '/calendario',
        element: (<Suspense fallback={<Load/>}> <CalendarioScreen /> </Suspense>)
    },
    {
        path: '/ranking',
        element: (<Suspense fallback={<Load/>}> <RankingScreen /> </Suspense>)
    },
    {
        path: '/beneficios',
        element: (<Suspense fallback={<Load/>}> <BeneficiosScreen /> </Suspense>)
    }
]
