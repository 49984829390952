import {useContext,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate} from "react-router-dom";
import { startLogin } from '../../actions';
import {ContextMenu} from '../../context/MenuContext';
import {infoSwal,inputMailSwal,waitSwal} from '../../helpers/swal';
import { fetchCustome2 } from '../../helpers/fetch';
import {maskEmail,email} from '../../helpers/validaciones';

export const LoginScreen = ()=>{

    const {name} = useSelector( store => store.auth );
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const {setActive} = useContext(ContextMenu);

    const [form,setForm] = useState({
        email:'',
        password : '' 
    });

    const onChangeForm = (e)=>{

        let value = '';
        if(e.target.name === "email")
            value = maskEmail(e.target.value);
        else
            value = e.target.value;

        setForm({
            ...form,
            [e.target.name] : value
        });
    }

    const [input, setInput] = useState({
        type:'password',
        check:'fa-square color-disabled-check'
    });

    const changeType = (val)=>{
        let [type,check] = ['password','fa-square color-disabled-check'];
        
        if(val === 'password'){
            type = 'text';
            check = 'fa-check-square color-enabled-check';
        }

        setInput({
            type,
            check
        });
    }

    const sendSubmit = (e)=>{
        e.preventDefault();
        if(!validateCustome()){
            dispatch( startLogin(form.email,form.password) );
        }
    }

    const validateCustome= ()=>{
        
        if(email(form.email)){
            infoSwal({title:'Email',text:'Debe ser un email valido'});
            return true;
        }
        if(form.password.trim() === "" ){
            infoSwal({title:'Contraseña',text:'Verifica tu contraseña'});
            return true;
        }
        return false;//Si error
    }

    const resetPass = ()=>{
        inputMailSwal(async function(email){
            if (email) {
                try{
                    waitSwal({html:'Espere...'});
                    await fetchCustome2({ 
                        endpoint : '/mail/recover',
                        method :'POST',
                        body:{
                            email
                        },
                        json:true,
                        token:false
                    });
                    infoSwal({icon: 'success',title: '',text: "En breve recibiras un correo con las instrucciones para recuperar tu contraseña",timer:30000});
                }
                catch(e){
                    infoSwal({icon: 'error',title: '',text: "Error con el servidor, intentelo más tarde",});
                }
            }
        })
    }

    const cerrarLogin = ()=>{
        setActive({ '/inicio' :'menu-active'});
        navigate('/inicio');
    }

    const afiliacion = ()=>{
        navigate('/afiliacion');
    }

    if(!!name)
        return <Navigate to="/inicio"/>;

    return(
        <>
            <div className="d-flex justify-content-center align-items-center" style={{padding:20,background:'#000',position:'absolute',height:'100vh',left:0,right:0,bottom:0,top:0,zIndex:1000,opacity:.94}}>
          
                <form onSubmit={sendSubmit} style={{width:400}}>
                    <div className="d-flex justify-content-center">
                        <div 
                        onClick={cerrarLogin}
                        style={{width:50,height:50,textAlign:'center',padding:12,borderRadius:'50%',fontSize:19,background:'#F20000',color:'#FFF',cursor:'pointer',opacity:.78}}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img src={process.env.PUBLIC_URL + '/assets/img/logo/logo39982020.png'} alt="logo" style={{width:80,marginTop:10}} />
                    </div>
                    <div className="form-outline mb-3">
                        <label className="form-label">Email</label>
                        <input name="email" type="text" value={form.email} className="form-control" autoComplete="off" onChange={onChangeForm}/>
                    </div>
                    <div className="form-outline mb-3">
                        <label className="form-label">Contraseña</label>
                        <input name="password" type={input.type} value={form.password} className="form-control" autoComplete="off" onChange={onChangeForm}/>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div style={{position:'absolute',cursor:'pointer',zIndex:3}} onClick={()=>changeType(input.type)}>
                                <i className={`fa ${input.check} fa-2x me-1`} style={{cursor:'pointer'}}></i>
                                <label className="custom-control-label ml-3" style={{cursor:'pointer'}}>Mostrar</label>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end mt-2">
                            <span style={{color:'#F20000',cursor:'pointer'}} onClick={resetPass}>Olvidaste tu contraseña?</span>
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-danger btn-lg btn-block mb-4" style={{width:'100%',fontSize:18,background:'#F20000'}}>Iniciar sesión</button>
                    </div>

                    <div className="text-center">
                        <p>No eres miembro? <span style={{color:'#F20000',cursor:'pointer'}} onClick={afiliacion}>Afiliate</span></p>
                    </div>
                </form>
            </div>
  
  
</>
    )
}