import {createContext,useState} from 'react';

export const ContextRanking = createContext();

export const RankingProvider = ({children})=>{
    
    //indicamos la pantalla a amostrar 
    //----> 0 listamos las categorias
    //----> 1 listamos el detalle de una categoria
    const [screen,setScreen] = useState(0);

    //Indica el id de la categoria seleccionada
    const [idCategory,setIdCategory] = useState(0);

    //Indica el nombre de la categoria seleccionada
    const [nameCategory,setnameCategory] = useState('');

    return (
        <ContextRanking.Provider value={{
            screen,setScreen,
            idCategory,setIdCategory,
            nameCategory,setnameCategory
        }}>
            {children}
        </ContextRanking.Provider>
    )
}

