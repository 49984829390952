import React, {useContext,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link,useLocation,useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import {customeContext} from '../../context/context';
import {ContextMenu} from '../../context/MenuContext';
import { menus} from '../../routes/tipos/menus';

const MenuBurger = ({sideBar,title})=>{
    return(
        <div className="row align-items-center">
            <div className="col-6 clearfix">
                <span style={{fontSize:28,color:'#F20000'}}>{title}</span>
            </div>
            <div className="col-6 d-flex justify-content-end">
                <div className="nav-btn pull-left" onClick={()=>sideBar(state => !state)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}

const MenuTop = ({scrollPosition})=>{

    const {name,image,access} = useSelector( store => store.auth );
    const navigate = useNavigate();
    const {active,setActive} = useContext(ContextMenu);

    const {pathname:location} = useLocation();

    const changeActive = (val)=>{
        setActive({
            [val]:'menu-active'
        });
    }

    useEffect(() => {
        setActive({
            [location] :'menu-active'
        });
    },[]);

    const logout = (e)=>{
        e.preventDefault();
        Swal.fire({
            title: '¿ Deseas cerrar la sesión ?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cerrar',
            cancelButtonText: 'No'
          }).then((result) => {
            if(result.isConfirmed)
                navigate('/logout')
          })
    }

    return(
        <div className={`header ${scrollPosition > 100 && 'header-scrolled'} d-flex justify-content-between align-items-center`}>
            <div className="logo">
                <div className="logo-header-main d-flex">
                    <img 
                        src={process.env.PUBLIC_URL + '/assets/img/logo/logo39982020.png'} 
                        alt="logo"
                    />
                    {/* <div style={{marginLeft:10,textAlign:'center',marginTop:16}}>
                        <span style={{color:'#006231',fontWeight:800}}>ASOCIACIÓN</span>
                        <span style={{color:'#F20000',fontWeight:800}}>PÁDEL</span>
                        <span style={{color:'#F20000',fontWeight:800}}>JALISCO</span>
                    </div> */}
                </div>
            </div>
            <nav className="nav-menu-container">
                <ul className="nav-menu">
                    {
                         menus(access).map(option =>(
                            <li className={active[`${option.to}`]} key={option.name}>
                                <Link
                                    className="link-principal"
                                    to={`${option.to}`}
                                    onClick={()=>changeActive(`${option.to}`)}
                                >
                                    {
                                      option.name  === "Iniciar sesión" ||  option.name  === "Afiliarme" ?
                                      <b><u>{option.name}</u></b> : 
                                      option.name 
                                      
                                    }
                                  
                                </Link>
                            </li>
                        ))
                    }

                    {
                        !!name &&

                        <li>
                            <Link
                                className="link-principal"
                                to=""
                                onClick={logout}
                            >
                                Salir
                            </Link>
                        </li>

                    }
                </ul>
            </nav>
            <div className="position-absolute" style={{right:23,top:55,color:'gray',opacity:.8,fontSize:14}}>{name}</div>
        </div>
    )
}

export const Header = ({setShowHideSideBar,widthScreen,scrollPosition}) => {

    const {context} = useContext(customeContext);

    return (
        <>
            <div className={`${ widthScreen <= 768 ? 'header-area' :'header-area2' }`} >
                {
                    widthScreen <= 768 
                    ?
                    <MenuBurger sideBar={setShowHideSideBar} title={context.titlePage}/>
                    :
                    <MenuTop scrollPosition={scrollPosition}/>
                }
            </div>    
        </>
    )
}
