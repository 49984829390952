import { Suspense, lazy } from 'react';
import { Load, LoginScreen} from '../../componets';


const HomeScreen = lazy(() => import('../../componets/home/HomeScreen'));
const RankingScreen = lazy(() => import('../../componets/ranking/RankingScreen'));
const TorneosScreen = lazy(() => import('../../componets/torneos/TorneosScreen'));
const CalendarioScreen = lazy(() => import('../../componets/calendario/CalendarioScreen'));
const GaleriasScreen = lazy(() => import('../../componets/galerias/GaleriasScreen'));
const ReglamentosScreen = lazy(() => import('../../componets/reglamentos/ReglamentosScreen'));
const AdministracionScreen = lazy(() => import('../../componets/administracion/AdministracionScreen'));

const Administradores = lazy(() => import('../../componets/administracion/administradores/AdministrdoresScreen'));
const AfiliadosScreen = lazy(() => import('../../componets/administracion/afiliados/editar/AfiliadosScreen'));
const TorneosScreenAdmin = lazy(() => import('../../componets/administracion/torneos/editar/TorneosScreen'));
const TorneosScreenNuevo = lazy(() => import('../../componets/administracion/torneos/nuevo/TorneosScreen'));
const OtrosScreen = lazy(() => import('../../componets/administracion/otros/OtrosScreen'));

const PerfilScreen = lazy(() => import('../../componets/perfil/PerfilScreen'));

const BeneficiosScreen = lazy(()=>import('../../componets/beneficios/BeneficiosScreen'));


export const rutasAdministrador = [
    {
        path: '/login',
        element: <LoginScreen />
    },
    {
        path: '/afiliados',
        element: (<Suspense fallback={<Load/>}> <AfiliadosScreen /> </Suspense>)
    },
    {
        path: '/administracion',
        element: (<Suspense fallback={<Load/>}> <AdministracionScreen /> </Suspense>)
    },
    {
        path: '/inicio',
        element: (<Suspense fallback={<Load/>}> <HomeScreen /> </Suspense>)
    },
    {
        path: '/perfil',
        element: (<Suspense fallback={<Load/>}> <PerfilScreen /> </Suspense>)
    },
    {
        path: '/torneos',
        element: (<Suspense fallback={<Load/>}> <TorneosScreen /> </Suspense>)
    },
    {
        path: '/administracion-torneos',
        element: (<Suspense fallback={<Load/>}> <TorneosScreenAdmin /> </Suspense>)
    },
    {
        path: '/administracion-nuevo-torneo',
        element: (<Suspense fallback={<Load/>}> <TorneosScreenNuevo /> </Suspense>)
    },
    {
        path: '/reglamentos',
        element: (<Suspense fallback={<Load/>}> <ReglamentosScreen /> </Suspense>)
    },
    {
        path: '/galerias',
        element: (<Suspense fallback={<Load/>}> <GaleriasScreen /> </Suspense>)
    },
    {
        path: '/ranking',
        element: (<Suspense fallback={<Load/>}> <RankingScreen /> </Suspense>)
    },
    {
        path: '/calendario',
        element: (<Suspense fallback={<Load/>}> <CalendarioScreen /> </Suspense>)
    },
    {
        path: '/otros',
        element: (<Suspense fallback={<Load/>}> <OtrosScreen /> </Suspense>)
    },
    {
        path: '/administradores',
        element: (<Suspense fallback={<Load/>}> <Administradores /> </Suspense>)
    },
    {
        path: '/beneficios',
        element: (<Suspense fallback={<Load/>}> <BeneficiosScreen /> </Suspense>)
    },
];


